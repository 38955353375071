<template>
    <!-- Show loading spinner until dashboard is loaded -->
    <div v-if="loading">
        <LoadingSpinner :isLoading="loading"></LoadingSpinner>
    </div>

    <div v-else class="mt-3">
        <div class="card mt-3 mx-auto" v-if="currentMeteor">
            <MeteorTiny :meteor="currentMeteor" :meteorData="currentMeteorData" />
        </div>
        <div class="d-flex justify-content-center" v-else>
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</template>
  
<script>

import MeteorDataService from "@/services/meteor.service";
import { stripDate, generateVideoUrl } from "@/components/helpers/helpers.js";
import MeteorTiny from "@/components/MeteorTiny.vue";
import LoadingSpinner from '@/components/visualization/LoadingSpinner.vue';

export default {
    name: 'MeteorDetails',
    components: {
        MeteorTiny,
        LoadingSpinner,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            currentMeteor: null,
            currentMeteorData: null,
            loading: true,
        };
    },



    methods: {

        stripDate,
        generateVideoUrl,

        /**
        * This function retrieves meteor data using MeteorDataService and processes the response
        * to generate video URLs for each observation camera.
        * The video URL is then added to the meteorDataResponse object
        * 
        * @param {string} id - The meteor ID.
        * @returns {Promise<void>} - A promise that resolves when the data has been fetched and processed.
        */
        async getMeteor(id) {
                try {
                    const response = await MeteorDataService.get(id);
                    const meteorDataResponse = response.data;
                    const observationCamData = meteorDataResponse.observation_cam_data;

                    observationCamData.forEach((observation) => {
                        const date = this.stripDate(observation.video_start);
                        const videoUrl = this.generateVideoUrl(meteorDataResponse.datetimetag, observation.cam, date);

                        observation.videourl = videoUrl;

                    });

                    this.currentMeteor = meteorDataResponse;
                    this.currentMeteorData = meteorDataResponse;

                    this.loading = false;
                } catch (e) {
                    console.error(e);
                    this.loading = true;
                }
            },


        },

        created() {


            /* Wait to simulate slow service */
            setTimeout(() => {
                this.getMeteor(this.id);
            }, 100);


        },



    }
</script>
  
<style scoped>
.card {
    max-width: 800px;
}
</style>
  